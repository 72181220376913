import { DeviceSchema, MdmDeviceSchema } from '@/generated/sdk'
import { InjectionKey, inject, provide, ref } from 'vue'
import { apiComposable } from '../../app/composables/api'

const { sdk } = apiComposable()

type Device = {
  mdmDevice: MdmDeviceSchema
  googleDevice: DeviceSchema
}

export function initDevice() {
  const device = ref<Device>({
    mdmDevice: {} as MdmDeviceSchema,
    googleDevice: {} as DeviceSchema,
  })

  const groupedDeviceDetails = ref({
    general: [],
    enrollment: [],
    hardware: [],
    software: [],
    network: [],
    security: [],
  })

  async function setDevice(mdmDeviceId: string) {
    // First, load the MDM device
    const { mdmDevice } = await sdk().mdmDevices({ input: { id: mdmDeviceId } })
    if (!mdmDevice.length) throw new Error('MDM Device not found')
    device.value.mdmDevice = mdmDevice[0] as MdmDeviceSchema

    // Then, load the Google device
    const deviceId: string = device.value.mdmDevice.googleId ?? null
    const { device: googleDevice } = await sdk().googleDevice({ deviceId })
    if (!googleDevice.length) throw new Error('Google Device not found')
    device.value.googleDevice = googleDevice[0] as DeviceSchema

    setGroupedDeviceDetails()
  }

  function setGroupedDeviceDetails() {


    for (const [key, value] of Object.entries(device.value.googleDevice)) {
      if (key === 'id') {
        addValueToGroup('id', device.value.mdmDevice.id)
      } else if (key === 'networkInfo') {
        for (const [networkkey, networkvalue] of Object.entries(value)) {
          addValueToGroup(networkkey, networkvalue)
        }
      } else {
        addValueToGroup(key, value)
      }
    }
  }

  function addValueToGroup(key: string, value: any) {
    const group = Object.entries(deviceConfig).find(([_, groupKeys]) => Object.keys(groupKeys).includes(key))
    if (!group) return
    groupedDeviceDetails.value[group[0]].push({ key, value })
  }

  const deviceConfig = {
    general: {
      id: 'string',
      name: 'id',
      userName: 'id',
      user: 'json',
      ownership: 'string',
      state: 'string',
      appliedState: 'string',
      previousDeviceNames: 'json',
    },
    enrollment: {
      policyCompliant: 'boolean',
      enrollmentTime: 'date',
      enrollmentTokenData: 'string',
      enrollmentTokenName: 'id',
      lastPolicySyncTime: 'date',
      lastPolicyComplianceReportTime: 'date',
      lastStatusReportTime: 'date',
      appliedPolicyVersion: 'string',
    },
    hardware: {
      hardwareInfo: 'json',
      hardwareStatusSamples: 'json',
      memoryInfo: 'json',
      memoryEvents: 'json',
      powerManagementEvents: 'json',
      displays: 'json',
    },
    software: {
      apiLevel: 'string',
      nonComplianceDetails: 'json',
      applicationReports: 'json',
      softwareInfo: 'json',
      systemProperties: 'json',
    },
    network: {
      imei: 'string',
      meid: 'string',
      networkOperatorName: 'string',
      telephonyInfos: 'json',
      wifiMacAddress: 'string',
    },
    security: {
      appliedPasswordPolicies: 'json',
      commonCriteriaModeInfo: 'json',
      deviceSettings: 'json',
      disabledReason: 'json',
      managementMode: 'string',
      securityPosture: 'json',
    },
  }

  return {
    device,
    setDevice,
    groupedDeviceDetails,
    deviceConfig,
  }
}

export const deviceKey: InjectionKey<ReturnType<typeof initDevice>> = Symbol('device')

export function useDevice() {
  const device = inject(deviceKey)
  if (!device) throw new Error('Device plugin is not installed')
  return device
}

export function useProvideDevice() {
  const device = initDevice()
  provide(deviceKey, device)
  return device
}