import { getSdk } from '@/generated/sdk'
import { GraphQLClient } from 'graphql-request'

export type Sdk = ReturnType<typeof getSdk>

export function apiComposable() {
  function sdk(): Sdk {
    const client = new GraphQLClient(`${process.env.VUE_APP_API_URL}/graphql`)
    client.setHeader(
      'Authorization',
      'Bearer ' + localStorage.getItem('apiKey'),
    )
    return getSdk(client)
  }
  
  return { sdk }
}
