<script setup lang="ts">
import { computed } from 'vue'
import { EnterpriseSchema } from '@/generated/sdk'
import { appComposable } from '@/plugins/app/composables/app'
const { enterpriseRef } = appComposable()

const enterprise = computed<EnterpriseSchema>(() => enterpriseRef.value)
</script>

<template>
  <DodoColumn>
    <DodoRow>
      <h1>{{ enterprise.enterpriseDisplayName }} ({{ enterprise.name }})</h1>
    </DodoRow>
    <DodoCard>
      <h3>QR Code</h3>
      <QrCode />
    </DodoCard>
  </DodoColumn>
</template>
