<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import type { ConfigProperty } from './config'
import { formatJSON } from '@/utils/format-util'

const props = defineProps<{
  configProperties: ConfigProperty[]
  hasErrors: boolean
}>()

const emit = defineEmits(['update:hasErrors', 'checkDuplicates'])

const showAdvancedProperties = ref<boolean>(false)
const standardProperties = ref<ConfigProperty[]>(
  props.configProperties.filter((p) => !isAdvancedProperty(p)),
)
const advancedProperties = ref<ConfigProperty[]>(
  props.configProperties.filter((p) => isAdvancedProperty(p)),
)

onMounted(() => {
  props.configProperties.forEach((p) => {
    if (p.type === 'json' && p.value != null) {
      if (typeof p.value === 'string') {
        // if json was converted to string during parsing, convert it back to json
        p.value = JSON.parse(p.value)
      }

      p.value = formatJSON(JSON.stringify(p.value))
    }
  })
})

watch(props.configProperties, (properties) => {
  properties.some((p) => p.error === true && p.id !== 'packageName')
    ? emit('update:hasErrors', true)
    : emit('update:hasErrors', false)
})

function checkForJsonError(configProp: ConfigProperty) {
  if (configProp.value != null && configProp.value !== '') {
    try {
      configProp.error = false
      configProp.value = formatJSON(configProp.value)
    } catch (e) {
      configProp.error = true
    }
  } else {
    configProp.value = null
    configProp.error = false
  }
}

function isAdvancedProperty(property: ConfigProperty) {
  return property.advanced ?? false
}

function checkDuplicates(data: any) {
  emit('checkDuplicates', data)
}
</script>

<template>
  <div
    v-for="(properties, index) in [standardProperties, advancedProperties]"
    :key="index"
  >
    <div
      v-if="properties === advancedProperties && properties.length > 0"
      class="advanced-properties"
    >
      <DodoButton
        rounded
        @click="showAdvancedProperties = !showAdvancedProperties"
      >
        <DodoIcon
          :name="showAdvancedProperties ? 'expand_less' : 'expand_more'"
        />
        Advanced properties
      </DodoButton>
    </div>

    <DataTable
      :value="properties"
      :hidden="properties === advancedProperties && !showAdvancedProperties"
      table-style="width: 100%"
    >
      <PrimeColumn
        field="property"
        header="Property"
        style="width: 40%">
        <template #body="{ data: { property } }">
          <div>{{ property }}</div>
        </template>
      </PrimeColumn>

      <PrimeColumn
        field="value"
        header="Value"
        style="width: 60%">
        <template #body="{ data }">
          <DodoRow v-if="data.type == 'packageName'">
            <InputText
              v-model="data.value"
              placeholder="Search package, or insert"
              :class="{ 'p-invalid': data.error ?? false }"
              @input="checkDuplicates(data.value)"
            />

            <PlayStoreFinder v-model:packageName="data.value" />
          </DodoRow>

          <InputText
            v-else-if="data.type == 'string'"
            v-model="data.value"
            placeholder="Insert text"
            :class="{ 'p-invalid': data.error ?? false }"
          />

          <InputNumber
            v-else-if="data.type == 'integer'"
            v-model="data.value"
            placeholder="0"
          />

          <PrimeTextarea
            v-else-if="data.type == 'json'"
            v-model="data.value"
            placeholder="Insert json"
            rows="7"
            :class="{ 'p-invalid': data.error ?? false }"
            @input="checkForJsonError(data)"
          />

          <PrimeDropdown
            v-else-if="data.type == 'dropdown'"
            v-model="data.value"
            :options="data.options"
            placeholder="Select an option"
            show-clear
          />

          <MultiSelect
            v-else-if="data.type == 'multiselect'"
            v-model="data.value"
            :options="data.options"
            placeholder="Select one or more options"
            :max-selected-labels="1"
          />

          <PrimeCheckbox
            v-else-if="data.type == 'boolean'"
            v-model="data.value"
            :binary="true"
          />

          <template v-else> template not found </template>
        </template>
      </PrimeColumn>
    </DataTable>
  </div>
</template>

<style scoped>
input,
.p-dropdown,
.p-multiselect,
.p-inputtextarea,
.p-inputnumber {
  width: 100%;
  resize: vertical;
}

::placeholder {
  color: #d3d3d3;
}

.checkbox-label {
  color: #d3d3d3;
  margin-left: 10px;
}
.p-datatable,
.advanced-properties {
  margin-bottom: 20px;
}

.advanced-properties {
  display: flex;
  justify-content: center;
}
</style>
