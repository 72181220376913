<script setup lang="ts">
import type { Ref } from 'vue'
import { ref, onMounted } from 'vue'

import { EnrollmentTokenSchema, MdmPolicySchema } from '@/generated/sdk'
import QrCodeRender from 'qrcode'
import { formatJSON } from '@/utils/format-util'
import { tokensComposable } from '@/plugins/app/composables/tokens'
const { createEnrollmentToken, sync } = tokensComposable()

const props = defineProps<{
  policy?: MdmPolicySchema
}>()

const enrollmentToken: Ref<EnrollmentTokenSchema> = ref(null)
const canvas: Ref<HTMLCanvasElement> = ref(null)
const loading = ref(false)
const error = ref()
const qrData = ref()
const disabled = ref(true)
const formatted = ref(true)

onMounted(async () => {
  loading.value = true
  await createToken()
})

function checkForJsonError(json: string) {
  if (json != null) {
    try {
      JSON.parse(json)
      error.value = false
      hasChanged()
    } catch (e) {
      error.value = true
    }
  } else {
    error.value = false
  }
}

async function createToken(): Promise<void> {
  try {
    enrollmentToken.value = await createEnrollmentToken({
      mdmPolicy: props.policy,
    })

    qrData.value = formatJSON(enrollmentToken.value.qrCode)

    await renderQr()
  } catch (e) {
    console.log('Something went wrong', e)
  }
}

async function renderQr(): Promise<void> {
  loading.value = true
  disabled.value = true

  formatTextArea()

  try {
    await QrCodeRender.toCanvas(canvas.value, qrData.value, {
      toSJISFunc: QrCodeRender.toSJIS,
    })
  } catch (e) {
    console.log('Something went wrong', e)
  }

  loading.value = false
}

function hasChanged() {
  disabled.value = qrData.value == formatJSON(enrollmentToken.value.qrCode)
  formatted.value = false
}

function formatTextArea() {
  qrData.value = formatJSON(qrData.value)
  formatted.value = true
}

async function syncZeroTouch(): Promise<void> {
  await sync({
    mdmPolicy: props.policy,
    qrCode: qrData.value,
  })
  alert('Synced successfully')
}
</script>

<template>
  <DodoColumn>
    <DodoButton
      color="info"
      variant="solid"
      @click="syncZeroTouch">
      Sync with Zero-touch
    </DodoButton>
    <DodoRow justify="center">
      <PrimeSkeleton
        :class="{ hidden: !loading }"
        width="436px"
        height="436px"
      />
      <canvas ref="canvas" :class="{ hidden: loading }"></canvas>
    </DodoRow>
    <DodoColumn v-if="qrData == null || !loading">
      <DodoRow
        justify="between"
        align="end"
        padding="2"
        gap="4">
        <DodoRow justify="between">
          <DodoButton
            variant="solid"
            color="primary"
            :disabled="formatted"
            @click="formatTextArea"
          >
            <DodoIcon name="format_indent_increase" />
            Format JSON
          </DodoButton>
        </DodoRow>
        <DodoRow>
          <DodoButton
            variant="solid"
            color="success"
            :disabled="error || disabled"
            @click="renderQr"
          >
            <DodoIcon name="autorenew" />
            Regenerate QR
          </DodoButton>
        </DodoRow>
      </DodoRow>

      <DodoRow>
        <PrimeTextarea
          v-model="qrData"
          auto-resize
          placeholder="Insert json"
          :class="{ 'p-invalid': error ?? false }"
          class="text-area"
          @input="checkForJsonError(qrData)"
        />
      </DodoRow>
    </DodoColumn>
  </DodoColumn>
</template>

<style scoped>
.hidden {
  display: none;
}

.text-area {
  width: 100%;
  font-size: 12px;
}
</style>
