<script setup lang="ts">
import { format } from 'date-fns'
import yaml from 'js-yaml'
import { ConfigProperty } from './config'
import { PolicySchema } from '@/generated/sdk'
import { computed } from 'vue'

const props = defineProps<{
  policyConfig: PolicySchema | null
  settingsConfig: ConfigProperty[]
  editMode: boolean
}>()

const tableData = computed(() => {
  const result = []
  for (const key in props.policyConfig) {
    // hide properties with null values except for applications
    if (props.policyConfig[key] == null && key !== 'applications') continue
    result.push({
      key,
      value: props.policyConfig[key],
      type: props.settingsConfig.find(p => p.id === key)?.type ?? null,
    })
  }
  return result
})

function formatValue(key: string, value: any, type: string): string {
  if (type === 'date') return format(new Date(value), 'dd-MM-yy HH:mm:ss')
  if (type === 'id') return value.split('/')[3]
  if (type === 'boolean') return value === true ? 'Yes' : 'No'
  if (type === 'json' || type === 'multiselect') return yaml.dump(value)
  return value
}

function formatKey(key: string) {
  const result = key.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase()
}
</script>

<template>
  <DataTable
    :value="tableData"
    class="table-padding"
    selection-mode="single">
    <PrimeColumn
      field="key"
      header-style="display:none;"
      style="width: 30%; vertical-align: baseline; background-color: #f9f9f9;"
    >
      <template #body="{ data: { key } }">
        <span class="key-field">
          {{ formatKey(key) }}
        </span>
      </template>
    </PrimeColumn>
    <PrimeColumn
      field="value"
      style="width: 70%"
      header-style="display:none;">
      <template #body="{ data: { key, value, type } }">
        <PolicyConfigurationApplications
          v-if="key === 'applications'"
          :application-list="value"
          :edit-mode="editMode"
        />

        <span v-else class="value-field">
          {{ formatValue(key, value, type) }}
        </span>
      </template>
    </PrimeColumn>
  </DataTable>
</template>

<style scoped>
.table-padding {
  border: 2px solid #f0f0f0;
}
.value-null {
  color: #dadada;
}
.key-field {
  display: block;
  font-weight: bold;
  text-align: right;
  color: #5a5a5a;
  text-transform: uppercase;
}
.value-field {
  white-space: pre-wrap;
  font-family: monospace;
}
</style>
