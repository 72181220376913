<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { MdmPolicySchema, StatsQuery } from '@/generated/sdk'
import { policiesComposable } from '@/plugins/policies/composables/policies'
import { appComposable } from '@/plugins/app/composables/app'

type TreeNode = {
  key: any
  label: any
  data: any
  icon: any
  children: any[]
}

const { mdmPoliciesRef, createNewPolicy } = policiesComposable()
const { statsRef } = appComposable()

const router = useRouter()

const sortField = ref('name')
const sortOrder = ref(1)
const filter = ref('')
const showDialog = ref(false)
const creationLoading = ref(false)
const newPolicyName = ref('')

const stats = computed<StatsQuery>(() => statsRef.value)

const mdmPolicies = computed(() => {
  return mdmPoliciesRef.value
    ?.filter((p) => {
      if (!filter.value.trim()) return true
      return p.name.toLowerCase().includes(filter.value.trim().toLowerCase())
    })
    .sort((a, b) => {
      let result = 0
      if (sortField.value === 'nApps') {
        result = (a.policy?.applications?.length || 0) - (b.policy?.applications?.length || 0)
      } else {
        // Sort by name is default
        result = a.name.localeCompare(b.name)
      }
      return sortOrder.value * result
    })
  })

const expandedKeys = ref({})

const nodes = computed<TreeNode[]>(() => {
  const result: TreeNode[] = []
  let _expandedKeys = { ...expandedKeys.value }

  const policies = mdmPolicies.value.map((policy) => {
    _expandedKeys[policy.id] = true
    return {
      key: policy.id,
      label: policy.name,
      data: policy,
      icon: 'pi pi-folder',
      children: [],
    }
  })

  for (let policy of policies) {
    if (policy.data.parent?.id == null) {
      result.push(policy)
    } else {
      const parent = policies.find((n) => n.key === policy.data.parent?.id)
      if (parent) {
        parent.children.push(policy)
      }
    }
  }

  expandedKeys.value = _expandedKeys
  return result
})

function activeMdmDevicesByPolicy(policy: MdmPolicySchema) {
  return stats.value.countMdmDeviceByPolicyByDate.find((c) => c.group === policy.googleId)?.count || 0
}

function totalMdmDevicesByPolicy(policy: MdmPolicySchema) {
  return stats.value.countPerPolicy.find((c) => c.group === policy.googleId)?.count || 0
}

function rowClick(event) {
  router.push(`/policies/${event.key}`)
}

function sort(event) {
  sortField.value = event.sortField
  sortOrder.value = event.sortOrder
}

async function createPolicy() {
  showDialog.value = false
  creationLoading.value = true

  try {
    await createNewPolicy(newPolicyName.value)
    newPolicyName.value = ''
  } finally {
    creationLoading.value = false
  }
}
</script>

<template>
  <DodoColumn>
    <DodoRow justify="between">
      <h1 class="header-padding">Policies</h1>
      <DodoButton
        color="success"
        variant="solid"
        :disabled="creationLoading"
        @click="showDialog = true">
        <DodoIcon name="add" />
        Create new policy
      </DodoButton>
    </DodoRow>
    <DodoRow>
      <TreeTable
        :value="nodes"
        :expanded-keys="expandedKeys"
        responsive-layout="scroll"
        :paginator="true"
        :rows="25"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows-per-page-options="[10, 25, 100]"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        selection-mode="single"
        style="width: 100%"
        @sort="sort"
        @node-select="rowClick"
      >
        <template #header>
          <DodoRow justify="end">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <InputText v-model="filter" placeholder="Search" />
            </span>
          </DodoRow>
        </template>
        <PrimeColumn
          field="label"
          header="Name"
          :sortable="true"
          expander>
          <template #body="{ node }">
            {{ node.label }}
          </template>
        </PrimeColumn>
        <PrimeColumn
          field="nApps"
          header="Number of apps"
          :sortable="true">
          <template #body="{ node }">
            {{ node.data.policy?.applications?.length || 0 }}
          </template>
        </PrimeColumn>
        <PrimeColumn field="active" header="Active this month">
          <template #body="{ node }">
            {{ activeMdmDevicesByPolicy(node.data) }}
          </template>
        </PrimeColumn>
        <PrimeColumn field="total" header="Total">
          <template #body="{ node }">
            {{ totalMdmDevicesByPolicy(node.data) }}
          </template>
        </PrimeColumn>
      </TreeTable>
    </DodoRow>
  </DodoColumn>

  <DodoDialog :active="showDialog">
    <h3>Create new policy</h3>
    <p>Name</p>
    <InputText v-model="newPolicyName" placeholder="Name" />
    <template #controls>
      <DodoButton variant="text" @click="showDialog = false"> Cancel </DodoButton>
      <DodoButton
        variant="solid"
        color="info"
        @click="createPolicy">
        Create
      </DodoButton>
    </template>
  </DodoDialog>
</template>