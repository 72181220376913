<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { MdmPolicySchema } from '@/generated/sdk'
import { usePolicyConfig } from '@/plugins/policies/composables/usePolicyConfig'
import { policiesComposable } from '@/plugins/policies/composables/policies'

const { currentPolicyRef, mdmPoliciesRef, updateMdmPolicy, updateMdmPolicyWithMergeAndGoogleUpdate } = policiesComposable()

const toast = useToast()
const router = useRouter()
const currentMdmPolicy = computed<MdmPolicySchema>(() => currentPolicyRef.value)
const parents = computed(() => getParents(currentMdmPolicy.value))

const { policyConfig } = usePolicyConfig()

const possibleParentPolicies = computed<MdmPolicySchema[]>(() =>
  mdmPoliciesRef.value.filter(
    mdmPolicy =>
      mdmPolicy.id !== currentMdmPolicy.value?.id &&
      !getParents(mdmPolicy).find(parent => parent.id === currentMdmPolicy.value?.id),
  ),
)

const parentId = ref<string>(currentMdmPolicy.value?.parent?.id || '')

watch(currentMdmPolicy, () => {
  parentId.value = currentMdmPolicy.value?.parent?.id
})

function getParents(mdmPolicy: MdmPolicySchema): MdmPolicySchema[] {
  const parent = getParent(mdmPolicy)
  return parent ? ([parent].concat(getParents(parent))).reverse() : []
}

function getParent(mdmPolicy: MdmPolicySchema): MdmPolicySchema {
  return mdmPoliciesRef.value.find(m => m.id === mdmPolicy?.parent?.id)
}

async function tryUpdatePolicy() {
  try {
    await updatePolicy()
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Update failed',
      detail: 'Something went wrong while updating the policies',
      life: 3000,
    })
  }
}

async function updatePolicy() {

  if (parentId.value) {
    currentMdmPolicy.value.parent = {
      id: parentId.value,
    } as MdmPolicySchema
  } else {
    currentMdmPolicy.value.parent = null
  }

  if (confirm('Update policy? This will update the child policies as well. (This will only succeed if the config policy is valid)')) {
    // Let the backend handle the updating and merging.
    await updateMdmPolicy(currentMdmPolicy.value)
    await updateMdmPolicyWithMergeAndGoogleUpdate({ mdmPolicyId: currentMdmPolicy.value.id, newConfigPolicy: policyConfig.value })
    router.go(0)
  }
}

</script>

<template>
  <PrimePanel
    header="Parent policies"
    toggleable
    class="panel">
    <DodoColumn>
      <DodoRow v-if="parents.length" gap="0">
        <template v-for="(parent, index) of parents" :key="parent.id">
          <DodoChip :color="index === parents.length - 1 ? 'success' : 'foreground'">
            <template v-if="index == 0">Base: </template>
            <template v-else>Parent: </template>
            <router-link :to="`/policies/${parent.id}`">
              {{ parent.name }}
            </router-link>
          </DodoChip>
          <DodoIcon v-if="index < parents.length - 1" name="chevron_right" />
        </template>
      </DodoRow>
      <DodoRow>
        <PrimeDropdown
          v-model="parentId"
          class="dropdown"
          :options="possibleParentPolicies"
          :loading="possibleParentPolicies == null"
          :show-clear="true"
          option-value="id"
          option-label="name"
          placeholder="Select a parent policy" />
        <DodoButton
          variant="solid"
          color="success"
          @click="tryUpdatePolicy">
          <DodoIcon name="save" />
          Save
        </DodoButton>
      </DodoRow>
    </DodoColumn>
  </PrimePanel>
  <DodoCard>
    <PolicyConfigurationSettings />
    <DodoRow justify="end">
      <DodoButton
        variant="solid"
        color="success"
        @click="tryUpdatePolicy">
        <DodoIcon name="save" />
        Save configuration
      </DodoButton>
    </DodoRow>
  </DodoCard>

  <PrimePanel
    header="Managed configuration (BETA)"
    toggleable
    collapsed
    class="panel">
    <PlayStore />
  </PrimePanel>
</template>

<style scoped>
.panel {
  width: 100%;
}

.dropdown {
  min-width: 200px;
}

.result {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  margin: 0;
}

.p-inputtextarea {
  width: 100%;
  resize: vertical;
}
</style>
