import { policiesComposable } from './plugins/policies/composables/policies'
const { mdmPoliciesRef, loadMdmPolicies } = policiesComposable()
import { appComposable } from '@/plugins/app/composables/app'
const { apiKeyRef, enterpriseRef, loadEnterprises, loadStats } = appComposable()

export const authGuard = async (to, from, next): Promise<void> => {
  const apiKey = apiKeyRef.value

  // Check if there is an api key, redirect to login if there isn't
  if (apiKey == null) {
    return next('/login')
  }

  // Check if there already is an enterprise, continue if there is
  if (enterpriseRef.value && mdmPoliciesRef.value) {
    return next()
  }

  // Load the enterprises, redirect to login if this fails
  try {
    await loadEnterprises()
    await loadStats()
    await loadMdmPolicies()

    return next()
  } catch (e) {
    return next('/login')
  }
}
