<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { MdmPolicySchema } from '@/generated/sdk'
import { policiesComposable } from '@/plugins/policies/composables/policies'
import { useProvidePolicyConfig } from '@/plugins/policies/composables/usePolicyConfig'

const { setPolicyConfig } = useProvidePolicyConfig()
const { currentPolicyRef, mdmPoliciesRef, setCurrentPolicy } = policiesComposable()

const route = useRoute()
const policies = computed<MdmPolicySchema[]>(() => mdmPoliciesRef.value)
const currentPolicy = computed<MdmPolicySchema>(() => currentPolicyRef.value)

onMounted(async () => {
  const policyId = route.params.id
  const currentPolicy = policies.value.find(m => m.id === policyId)
  if (currentPolicy) {
    setCurrentPolicy(currentPolicy)
    await setPolicyConfig(currentPolicy.configPolicy)
  }
})
</script>

<template>
  <DodoColumn>
    <DodoRow>
      <h1>Policy: {{ currentPolicy?.name || '' }}</h1>
    </DodoRow>

    <DodoTabs
      :tabs="[
        { name: 'Configuration', slot: 'configuration' },
        { name: 'Enrollment', slot: 'enrollment' },
      ]"
    >
      <template #configuration>
        <PolicyConfiguration />
      </template>
      <template #enrollment>
        <DodoCard>
          <QrCode :policy="currentPolicy" />
        </DodoCard>
      </template>
    </DodoTabs>
  </DodoColumn>
</template>

<style lang="scss" scoped>
.mdm-l-panel {
  padding: 1rem;
  overflow-x: scroll;
}
</style>
