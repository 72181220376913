import {
  DeviceState,
  MdmDeviceInputSchema,
  MdmDeviceSchema,
  SwitchDeviceArgs,
  SwitchDeviceSchema,
} from '@/generated/sdk'
import { ref } from 'vue'
import { appComposable } from '@/plugins/app/composables/app'
import { apiComposable } from '@/plugins/app/composables/api'

const { sdk } = apiComposable()
const { enterpriseRef } = appComposable()

const mdmDevicesRef = ref<MdmDeviceSchema[]>(null)
const switchesRef = ref<SwitchDeviceSchema[]>(null)

export function devicesComposable() {
  async function loadMdmDevices(data: any) {
    const { mdmDevice } = await sdk().mdmDevices(data)
    mdmDevicesRef.value = mdmDevice as MdmDeviceSchema[]
  }
  async function loadSwitches(queryArgs: SwitchDeviceArgs) {
    const { switchDevice } = await sdk().switches({
      input: {
        enterpriseId: enterpriseRef.value.id,
      },
      queryArgs,
    })

    switchesRef.value = switchDevice as SwitchDeviceSchema[]
  }

  async function createMdmDevices(update: MdmDeviceInputSchema[]) {
    for (const d of update) {
      console.log(d)
      await sdk().createMdmDevice({ input: d })
    }
  }

  async function updateMdmDevices(update: MdmDeviceSchema[]) {
    for (const d of update) {
      console.log({
        mdmDeviceUpdate: d,
        deviceUpdate: {
          id: d.googleId,
          policyName: d.policyName,
          state: DeviceState.Active,
        },
      })
      await sdk().updateDevicePolicy({
        mdmDeviceUpdate: d,
        deviceUpdate: {
          id: d.googleId,
          policyName: d.policyName,
          state: DeviceState.Active,
        },
      })
    }
  }

  async function deleteMdmDevice(data: MdmDeviceSchema) {
    await sdk().deleteMdmDevice({ mdmDeviceId: data.id })
    if (data.googleId != null) {
      await sdk().deleteDevice({ deviceId: data.googleId })
    }
    mdmDevicesRef.value = mdmDevicesRef.value.filter((m) => m.id !== data.id)
  }

  return {
    mdmDevicesRef,
    switchesRef,
    loadMdmDevices,
    loadSwitches,
    createMdmDevices,
    updateMdmDevices,
    deleteMdmDevice,
  }
}
