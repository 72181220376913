import {
  MdmPolicyInputSchema,
  MdmPolicySchema,
  PolicyInputSchema,
} from '@/generated/sdk'
import { ref } from 'vue'
import { appComposable } from '@/plugins/app/composables/app'
import { apiComposable } from '@/plugins/app/composables/api'

const { enterpriseRef } = appComposable()
const { sdk } = apiComposable()

const currentPolicyRef = ref<MdmPolicySchema>(null)
const mdmPoliciesRef = ref<MdmPolicySchema[]>(null)

export function policiesComposable() {
  async function loadMdmPolicies() {
    const { mdmPolicy: mdmPolicies } = await sdk().mdmPolicies({
      queryArgs: {
        size: 1000,
      },
    })

    mdmPoliciesRef.value = mdmPolicies as MdmPolicySchema[]
  }

  async function createNewPolicy(newPolicyName: string) {
    const policy = await sdk().createPolicy({
      input: {
        statusReportingSettings: {
          // Only setting always on
          networkInfoEnabled: true,
        },
        enterprise: { id: enterpriseRef.value.id },
      },
    })

    const configPolicy = await sdk().createPolicy({
      input: {
        enterprise: { id: enterpriseRef.value.id },
      },
    })

    await sdk().createMdmPolicy({
      input: {
        enterpriseId: enterpriseRef.value.id,
        googleId: policy.createPolicy.id,
        configGoogleId: configPolicy.createPolicy.id,
        name: newPolicyName,
      },
    })

    await loadMdmPolicies()
  }

  function setCurrentPolicy(currentPolicy: MdmPolicySchema) {
    currentPolicyRef.value = currentPolicy
  }

  async function updateMdmPolicyWithMergeAndGoogleUpdate(options: {
    mdmPolicyId: string
    newConfigPolicy: PolicyInputSchema
  }) {
    await sdk().updateMdmPolicyWithMergeAndGoogleUpdate({
      mdmPolicyId: options.mdmPolicyId,
      newConfigPolicy: options.newConfigPolicy,
    })
  }

  async function updateMdmPolicy(policy: MdmPolicyInputSchema) {
    const clone = JSON.parse(JSON.stringify(policy))
    delete clone.policy
    delete clone.configPolicy
    await sdk().updateMdmPolicyCustom({ input: clone })
    await loadMdmPolicies()
  }

  return {
    currentPolicyRef,
    mdmPoliciesRef,
    loadMdmPolicies,
    createNewPolicy,
    setCurrentPolicy,
    updateMdmPolicyWithMergeAndGoogleUpdate,
    updateMdmPolicy,
  }
}
