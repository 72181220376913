<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { usePolicyConfig } from '@/plugins/policies/composables/usePolicyConfig'
import {
  ApplicationPolicySchema,
  MdmApplicationInputSchema,
  MdmApplicationSchema,
} from '@/generated/sdk'
import { ConfigProperty, createApplicationConfig } from './config'
import { formatJSON } from '@/utils/format-util'
import { apiComposable } from '../../app/composables/api'

const { sdk } = apiComposable()

const { policyConfig, getMdmApplicationsData } = usePolicyConfig()
const policyApplications = computed<ApplicationPolicySchema[]>(
  () => policyConfig.value?.applications ?? [],
)

const props = defineProps<{
  applicationList: ApplicationPolicySchema[] | null
  editMode: boolean
}>()

const applicationConfig = ref<ConfigProperty[]>(createApplicationConfig())
const showForm = ref(false)
const hasErrors = ref(false)
const hasDuplicate = ref(false)
const editApplicationIndex = ref(-1)

const tableData = ref<MdmApplicationSchema[]>(null)

watchEffect(async () => {
  if (props.applicationList == null) return
  await tryGetMdmApplicationsData()
})

async function tryGetMdmApplicationsData() {
  try {
    const input = props.applicationList.map((application) => ({
      name: application.packageName,
    })) as MdmApplicationInputSchema[]
    tableData.value = await getMdmApplicationsData(input)
  } catch (error) {
    tableData.value = []
  }
}

function editApplication(index: number) {
  const application = policyApplications.value[index]
  setFormValues(application)
  editApplicationIndex.value = index
  showForm.value = true
}

function removeApplication(index: number) {
  const newApplicationsConfig =
    policyApplications.value.toSpliced(index, 1) ?? null
  policyConfig.value.applications = newApplicationsConfig
}

async function saveApplication() {
  const packageName = applicationConfig.value.find(
    (property) => property.id === 'packageName',
  )
  if (packageName.value == null || packageName.value === '')
    return (packageName.error = true)

  try {
    await sdk().mdmApplication({ input: { name: packageName.value } })
  } catch (e) {
    return (packageName.error = true)
  }

  // if the policy does not have an applications array, create it
  if (policyConfig.value.applications == null) {
    policyConfig.value.applications = []
  }

  // parse properties according to their type
  const application: ApplicationPolicySchema = {}
  applicationConfig.value.forEach((property) => {
    if (property.type === 'json') {
      property.value = JSON.parse(property.value)
    }
    if (property.type === 'multiselect') {
      if (Array.isArray(property.value) && property.value.length === 0) {
        property.value = null
      }
    }
    application[property.id] = property.value
  })

  // add the application to the policy
  // if we are editing an application, replace it
  if (editApplicationIndex.value === -1) {
    policyConfig.value.applications.push(application)
  } else {
    policyConfig.value.applications[editApplicationIndex.value] = application
  }

  resetForm()
}

function setFormValues(application: ApplicationPolicySchema) {
  applicationConfig.value.forEach((property) => {
    if (application[property.id] == null) return

    if (property.type === 'json') {
      property.value = formatJSON(application[property.id])
    } else {
      property.value = application[property.id]
    }
  })
}

function resetForm() {
  applicationConfig.value = createApplicationConfig()
  editApplicationIndex.value = -1
  showForm.value = false
}

function checkDuplicates(value: string) {
  hasDuplicate.value =
    props.applicationList
      ?.map((application) => application.packageName)
      .includes(value) ?? false
}
</script>

<template>
  <DodoColumn>
    <DataTable
      v-if="tableData !== null && tableData.length > 0"
      :value="tableData"
      class="data-table"
      :class="{ 'no-margin': !editMode }"
    >
      <PrimeColumn style="width: 96px" header-class="no-header">
        <template #body="{ data }">
          <div class="icon">
            <img :src="data.iconUrl">
          </div>
        </template>
      </PrimeColumn>

      <PrimeColumn field="title" header-class="no-header" />
      <PrimeColumn
        v-if="editMode"
        header-class="no-header"
        class="text-right">
        <template #body="{ index }">
          <DodoButton
            rounded
            variant="text"
            @click="editApplication(index)">
            <DodoIcon name="edit" />
            Edit
          </DodoButton>
          <DodoButton
            rounded
            variant="text"
            color="danger"
            @click="removeApplication(index)"
          >
            <DodoIcon name="delete" />
            Remove
          </DodoButton>
        </template>
      </PrimeColumn>
    </DataTable>

    <DodoRow v-if="applicationList !== null && tableData == null">
      <DodoSpinner />
    </DodoRow>

    <DodoRow v-if="editMode">
      <DodoButton
        class="add-button"
        variant="solid"
        color="success"
        @click="showForm = true"
      >
        <DodoIcon name="add" />
        Add application
      </DodoButton>
    </DodoRow>
  </DodoColumn>

  <PrimeDialog
    v-model:visible="showForm"
    modal
    :header="editApplicationIndex >= 0 ? 'Edit Application' : 'New application'"
    :style="{ width: '65vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
    @hide="resetForm()"
  >
    <FormBuilder
      v-model:config-properties="applicationConfig"
      v-model:has-errors="hasErrors"
      @checkDuplicates="checkDuplicates"
    />

    <DodoColumn>
      <DodoRow>
        <DodoButton
          variant="solid"
          color="info"
          :disabled="hasErrors || hasDuplicate"
          @click="saveApplication"
        >
          Save application
        </DodoButton>
      </DodoRow>
      <div v-if="hasDuplicate">
        <DodoAlert
          style="font-size: 12px; color: red;"
          variant="warning"
          :closable="false"
          :dismissible="false">
          <DodoIcon name="warning" />
          <span> App is already in the policy</span>
        </DodoAlert>
      </div>
    </DodoColumn>
  </PrimeDialog>
</template>

<style scoped>
.data-table {
  margin: -1rem -1rem 0;
}

.no-margin {
  margin: -1rem;
}

.icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 5px 10px 2px #e0e0e0;
  overflow: hidden;
}

.icon img {
  width: 100%;
  height: 100%;
}
.text-right {
  text-align: right;
}
</style>