<script setup lang="ts">
import { MdmDeviceSchema, MdmPolicySchema } from '@/generated/sdk'
import { format } from 'date-fns'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { devicesComposable } from '@/plugins/devices/composables/devices'
import { appComposable } from '@/plugins/app/composables/app'
import { policiesComposable } from '@/plugins/policies/composables/policies'

const { mdmDevicesRef, loadMdmDevices, updateMdmDevices, deleteMdmDevice } = devicesComposable()
const { statsRef, enterpriseRef } = appComposable()
const { mdmPoliciesRef } = policiesComposable()

const router = useRouter()

const policyFilter = ref<string>(null)
const expectedPolicyFilter = ref<string>(null)
const filter = ref<string>('')
const filterFieldOptions = ref<string[]>(['IMEI', 'Serial'])
const filterField = ref<string>(filterFieldOptions.value[0])
const page = ref<number>(1)
const size = ref<number>(10)

const totalRecords = computed<number>(() => statsRef.value.countMdmDevice)
const mdmPolicies = computed<MdmPolicySchema[]>(() => mdmPoliciesRef.value)
const mdmDevices = computed<MdmDeviceSchema[]>(() => mdmDevicesRef.value)

watch([policyFilter, expectedPolicyFilter, filter, filterField], () => {
  loadsMdmDevices()
})

onMounted(async () => {
  await loadsMdmDevices()
})

async function loadsMdmDevices(): Promise<void> {
  const val = filter.value.trim() || undefined

  await loadMdmDevices({
    input: {
      imei: filterField.value === 'IMEI' ? val : undefined,
      serial: filterField.value !== 'IMEI' ? val : undefined,
      policyName: formatId(policyFilter.value) || undefined, // Hacky way to match only on id
      expectedPolicyName: formatId(expectedPolicyFilter.value) || undefined, // Hacky way to match only on id
      enterpriseId: enterpriseRef.value.id,
    },
    queryArgs: {
      page: page.value,
      size: val ? size.value + 10 : size.value, // Hacky way to get all results,
    },
  })
}

async function updateMdmDevicePolicy(e: MdmDeviceSchema): Promise<void> {
  const confirmMessage = `Update device with IMEI: ${e.imei} to "${formatPolicyName(e.policyName)}"?`
  if (window.confirm(confirmMessage)) {
    await updateMdmDevices([
      {
        id: e.id,
        googleId: e.googleId,
        policyName: e.policyName,
      } as MdmDeviceSchema,
    ])
  }
}

async function deleteDevice(e: MdmDeviceSchema): Promise<void> {
  if (window.confirm(`WARNING: Do you want to delete device with IMEI: ${e.imei}?`)) {
    await deleteMdmDevice(e)
  }
}

function formatId(id: string): string {
  return id?.split('/')[3]
}

function formatPolicyName(id: string): string {
  return mdmPolicies.value.find(m => m.googleId === id)?.name
}

function formatDate(date: string): string {
  return date ? format(new Date(date), 'dd-MM-yy HH:mm:ss') : undefined
}

async function onPage(event): Promise<void> {
  page.value = event.page + 1
  size.value = event.rows
  await loadsMdmDevices()
}

function rowClick(event) {
  router.push(`/devices/${event.data.id}`)
}
</script>

<template>
  <DodoColumn>
    <DodoRow justify="between">
      <h1 class="header-padding">Devices</h1>
      <DodoButton
        color="success"
        variant="solid"
        @click="$router.push('/policies/bulk')">
        <DodoIcon name="list_alt_add" />
        Bulk upload
      </DodoButton>
    </DodoRow>

    <DataTable
      v-if="mdmDevices"
      :lazy="true"
      :total-records="totalRecords"
      :value="mdmDevices"
      data-key="id"
      responsive-layout="scroll"
      :paginator="true"
      :rows="10"
      paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows-per-page-options="[10, 100, 1000]"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      filter-display="menu"
      selection-mode="single"
      @page="onPage($event)"
      @row-click="rowClick($event)"
    >
      <template #header>
        <DodoRow justify="between">
          <DodoRow>
            <PrimeDropdown
              v-model="policyFilter"
              :options="mdmPolicies"
              :loading="mdmPolicies == null"
              :show-clear="policyFilter !== null"
              option-value="googleId"
              option-label="name"
              placeholder="Filter by policy"
            />
            <PrimeDropdown
              v-model="expectedPolicyFilter"
              :options="mdmPolicies"
              :loading="mdmPolicies == null"
              :show-clear="expectedPolicyFilter !== null"
              option-value="googleId"
              option-label="name"
              placeholder="Filter by expected policy"
            />
          </DodoRow>
          <DodoRow>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <InputText v-model="filter" :placeholder="`Search`" />
            </span>
            <PrimeDropdown v-model="filterField" :options="filterFieldOptions" />
          </DodoRow>
        </DodoRow>
      </template>
      <PrimeColumn field="id" header="ID">
        <template #body="{ data }">
          {{ formatId(data.googleId) || '-' }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="lastPolicySyncTime" header="Last policy sync time">
        <template #body="{ data }">
          {{ formatDate(data.lastPolicySyncTime) || '-' }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="serial" header="Serial number">
        <template #body="{ data }">
          {{ data.serial || '-' }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="imei" header="IMEI">
        <template #body="{ data }">
          {{ data.imei || '-' }}
        </template>
      </PrimeColumn>
      <PrimeColumn field="policyName" header="Policy">
        <template #body="{ data }">
          <div class="p-inputgroup">
            <PrimeDropdown
              v-model="data.policyName"
              class="mdm-margin-right-s"
              :options="mdmPolicies"
              :loading="mdmPolicies == null"
              option-value="googleId"
              option-label="name"
              placeholder="Select a policy"
              :disabled="!data.googleId"
              @click.prevent.stop
            />
            <DodoButton
              variant="solid"
              class="mdm-margin-right-s"
              :disabled="!data.googleId"
              square
              color="info"
              @click.stop="updateMdmDevicePolicy(data)"
            >
              <DodoIcon name="checkmark" fill />
            </DodoButton>
            <DodoButton
              variant="solid"
              square
              color="danger"
              @click.stop="deleteDevice(data)">
              <DodoIcon name="delete" fill />
            </DodoButton>
          </div>
        </template>
      </PrimeColumn>
      <PrimeColumn field="expectedPolicyName" header="Expected policy">
        <template #body="{ data }">
          {{ formatPolicyName(data.expectedPolicyName) || '-' }}
        </template>
      </PrimeColumn>
    </DataTable>
  </DodoColumn>
</template>
