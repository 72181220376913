<script setup lang="ts">
import { ref } from 'vue'
import { tokensComposable } from '@/plugins/app/composables/tokens'
const { createWebToken } = tokensComposable()

declare const gapi: any

const container = ref<HTMLElement>(null)
const packageName = ref<string>('')
const mcmId = ref<string>(null)

function isHttps(): boolean {
  return location.protocol === 'https:'
}

async function loadManagedConfiguration() {
  // this call doesn'\t work for both store and composable
  const { value } = await createWebToken()

  let url = `https://play.google.com/managed/mcm?token=${value}&packageName=${packageName.value}`

  if (mcmId.value) {
    url += `&mcmId=${mcmId.value}`
  }
  gapi.load('gapi.iframes', () => {
    const options = {
      url,
      where: container.value,
      attributes: { style: 'width: 1000px; height:1000px', scrolling: 'yes' },
    }
    const iframe = gapi.iframes.getContext().openChild(options)
    iframe.register(
      'onconfigupdated',
      function (event) {
        console.log(event)
      },
      gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
    )

    iframe.register(
      'onconfigdeleted',
      function (event) {
        console.log(event)
      },
      gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
    )
  })
}
</script>

<template>
  <DodoColumn>
    <DodoRow justify="space-between">
      <DodoColumn>
        <DodoRow gap="2">
          <InputText v-model="packageName" placeholder="Package Name" />

          <InputText v-model="mcmId" placeholder="Managed Configuration Id (optional)" />

          <DodoButton v-if="isHttps" @click="loadManagedConfiguration()">
            Load Managed Configuration
          </DodoButton>
        </DodoRow> 
      </DodoColumn>
    </DodoRow>

    <DodoRow justify="center" class="store-wrapper">
      <div v-if="isHttps" ref="container"></div>
      <div v-else>Https (SSL) is required to load Play Store</div>
    </DodoRow>
  </DodoColumn>
</template>

<style scoped>
.store-wrapper {
  background-color: #f8f9fa;
  border-radius: 5px;
}
</style>