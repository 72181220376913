<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { usePolicyConfig } from '@/plugins/policies/composables/usePolicyConfig'
import { policiesComposable } from '@/plugins/policies/composables/policies'
import { ConfigProperty, createSettingsConfig } from './config'
import { MdmPolicySchema } from '@/generated/sdk'
const { currentPolicyRef } = policiesComposable()

const { policyConfig } = usePolicyConfig()
const formData = ref<ConfigProperty[]>(createSettingsConfig())

const currentMdmPolicy = computed<MdmPolicySchema>(() => currentPolicyRef.value)

const showSettingsForm = ref(false)
const showResult = ref(false)
const hasErrors = ref(false)

const tableConfig = createSettingsConfig()

onMounted(() => {
  if (policyConfig.value != null) {
    formData.value.forEach((property) => {
      if (policyConfig.value[property.id] == null) return

      property.value = policyConfig.value[property.id]
    })
  }
})

watch(policyConfig, () => {
  formData.value.forEach((property) => {
    if (policyConfig.value[property.id] == null) return

    property.value = policyConfig.value[property.id]
  })
})

function parseSettings(): void {
  // parse properties according to their type
  formData.value.forEach((property) => {
    if (property.type === 'json') {
      if (typeof property.value === 'string') {
        // if json was converted to string during parsing, convert it back to json
        property.value = JSON.parse(property.value)
      }
      property.value = property.value
    }
    if (property.type === 'multiselect') {
      if (Array.isArray(property.value) && property.value.length === 0) {
        property.value = null
      }
    }
    policyConfig.value[property.id] = property.value
  })
  showSettingsForm.value = false
}
</script>

<template>
  <DodoRow justify="between">
    <DodoColumn>
      <h3>Settings</h3>
    </DodoColumn>

    <DodoColumn>
      <DodoRow justify="end">
        <DodoButton
          variant="solid"
          color="info"
          @click="showResult = true">
          <DodoIcon name="visibility" />
          Show result
        </DodoButton>

        <DodoButton
          variant="solid"
          color="info"
          @click="showSettingsForm = true"
        >
          <DodoIcon name="edit" />
          Edit settings
        </DodoButton>
      </DodoRow>
    </DodoColumn>
  </DodoRow>

  <PolicyConfigurationSettingsTable
    :policy-config="policyConfig"
    :settings-config="tableConfig"
    :edit-mode="true"
  />

  <PrimeDialog
    v-model:visible="showSettingsForm"
    modal
    header="Adjust settings"
    :style="{ width: '65vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <FormBuilder
      v-model:config-properties="formData"
      v-model:has-errors="hasErrors"
    />

    <DodoButton
      variant="solid"
      color="info"
      :disabled="hasErrors"
      @click="parseSettings"
    >
      Apply changes
    </DodoButton>
  </PrimeDialog>

  <PrimeDialog
    v-model:visible="showResult"
    modal
    header="Result"
    :style="{ width: '65vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <PolicyConfigurationSettingsTable
      :policy-config="currentMdmPolicy.policy"
      :settings-config="tableConfig"
      :edit-mode="false"
    />
  </PrimeDialog>
</template>
