import { EnterpriseSchema, StatsQuery } from '@/generated/sdk'
import { ref } from 'vue'
import { apiComposable } from './api'

const { sdk } = apiComposable()
const apiKeyRef = ref<string>(localStorage.getItem('apiKey') || null)
const enterprisesRef = ref<EnterpriseSchema[]>(null)
const enterpriseRef = ref<EnterpriseSchema>(null)
const statsRef = ref<StatsQuery>(null)

export function appComposable() {
  async function loadEnterprises() {
    const { enterprise } = await sdk().enterprises()
    enterprisesRef.value = enterprise as EnterpriseSchema[]
    enterpriseRef.value = enterprise[0] as EnterpriseSchema
  }

  async function loadStats() {
    const stats = await sdk().stats()
    statsRef.value = stats as StatsQuery
  }

  function setApiKey(value: string) {
    localStorage.setItem('apiKey', value)
    apiKeyRef.value = value
    enterprisesRef.value = null
    enterpriseRef.value = null
  }

  return {
    apiKeyRef,
    enterprisesRef,
    enterpriseRef,
    statsRef,
    loadEnterprises,
    loadStats,
    setApiKey,
  }
}
