import { EnrollmentTokenSchema, MdmPolicySchema } from '@/generated/sdk'
import { apiComposable } from './api'
import { appComposable } from './app'

const { sdk } = apiComposable()
const { enterpriseRef } = appComposable()

export interface TokenCreateOptions {
  mdmPolicy?: MdmPolicySchema
}
export interface TokensState {}

export function tokensComposable() {
  async function createWebToken() {
    const { createWebToken: data } = await sdk().createWebToken({
      enterpriseId: enterpriseRef.value.id,
      parentFrameUrl: location.href,
    })
    return data
  }
  
  async function sync(options: { mdmPolicy: MdmPolicySchema, qrCode: string }) {
    await sdk().sync({
      mdmPolicyId: options.mdmPolicy.id,
      qrCode: options.qrCode,
    })
  }

  async function createEnrollmentToken(options: TokenCreateOptions) {
    let data: EnrollmentTokenSchema
    if (options.mdmPolicy) {
      const r = await sdk().createEnrollmentTokenForPolicy({
        policyId: options.mdmPolicy.googleId,
      })
      data = r.createEnrollmentToken
    } else {
      const r = await sdk().createEnrollmentTokenForEnterprise({
        enterpriseId: enterpriseRef.value.id,
      })
      data = r.createEnrollmentToken
    }
    // Make sure to add additional QR properties
    const qr = JSON.parse(data.qrCode)

    qr['android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED'] = true
    qr['android.app.extra.PROVISIONING_USE_MOBILE_DATA'] = true
    qr['android.app.extra.PROVISIONING_LOCALE'] = 'en_US'
    data.qrCode = JSON.stringify(qr)

    return data
  }

  return {
    createWebToken,
    sync,
    createEnrollmentToken,
  }
}
